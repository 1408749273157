import {Injectable} from '@angular/core';
import {UserRolesEnum} from '../enums/UserRolesEnum';
import {environment} from '../../environments/environment';
import {IUser} from '../interfaces/IUser';
import {UtilsService} from './utils.service';
import {User} from '@auth0/auth0-spa-js';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    keyRoles = `${environment.key_roles}roles`;
    roles: typeof UserRolesEnum = UserRolesEnum;
    aliases = {
        public: UserRolesEnum.PUBLIC,
        enregistre: UserRolesEnum.ENREGISTRE,
        super_utilisateur: UserRolesEnum.SUPER_UTILISATEUR,
        observateur: UserRolesEnum.OBSERVATEUR,
        validateur: UserRolesEnum.VALIDATEUR,
        admin: UserRolesEnum.ADMIN,
        demo: UserRolesEnum.DEMO,
        experimentation: UserRolesEnum.EXPERIMENTATION,
        carte: UserRolesEnum.CARTE,
        fenetre: UserRolesEnum.FENETRE,
        urbasense_corporate: UserRolesEnum.URBASENSE_CORPORATE,
        installateur: UserRolesEnum.INSTALLATEUR,
        simple_utilisateur: UserRolesEnum.SIMPLE_UTILISATEUR,
        responsable_commercial: UserRolesEnum.RESPONSABLE_COMMERCIAL,
        responsable_agronomique: UserRolesEnum.RESPONSABLE_AGRO,
        responsable_equipements: UserRolesEnum.RESPONSABLE_EQUIPEMENTS,
        responsable_icaa: UserRolesEnum.RESPONSABLE_ICAA,
        intervention_admin: UserRolesEnum.INTERVENTION_ADMIN
    };

    constructor(
        private utils: UtilsService,
    ) {
    }

    /**
     * Check if given User has a role.
     */
    hasRole(user: IUser | User, roles: UserRolesEnum | UserRolesEnum[]): boolean {
        if (!user) {
            return false;
        }
        const userRoles = this.parseUserRoles(user);
        return Array.isArray(roles) ?
            roles.some(r => userRoles.includes(r))
            : userRoles.includes(roles);
    }

    /**
     * UserRole parser.
     * Make sure to return user role as a UserRolesEnum array.
     */
    parseUserRoles(user: IUser | User): UserRolesEnum[] {
        console.log('parseUserRoles',user);
        console.log('this.keyRoles',this.keyRoles);
        let roles = JSON.parse(user[this.keyRoles]);
        if (typeof roles !== 'object') {
            // Handling the case where a user might have a single role
            // not inside an array
            roles = [roles] as UserRolesEnum[];
        }
        roles = roles as UserRolesEnum[];
        return roles.map((r: UserRolesEnum) => Number(r));
    }

    /**
     * Converts a role name into a UserRolesEnum code
     */
    roleAliasToCode(roleName: string): UserRolesEnum {
        // console.log('roleAliasToCode', roleName);
        const aliases = {
            public: UserRolesEnum.PUBLIC,
            enregistre: UserRolesEnum.ENREGISTRE,
            super_utilisateur: UserRolesEnum.SUPER_UTILISATEUR,
            observateur: UserRolesEnum.OBSERVATEUR,
            validateur: UserRolesEnum.VALIDATEUR,
            admin: UserRolesEnum.ADMIN,
            demo: UserRolesEnum.DEMO,
            experimentation: UserRolesEnum.EXPERIMENTATION,
            carte: UserRolesEnum.CARTE,
            fenetre: UserRolesEnum.FENETRE,
            urbasense_corporate: UserRolesEnum.URBASENSE_CORPORATE,
            installateur: UserRolesEnum.INSTALLATEUR,
            simple_utilisateur: UserRolesEnum.SIMPLE_UTILISATEUR,
            responsable_commercial: UserRolesEnum.RESPONSABLE_COMMERCIAL,
            responsable_agro: UserRolesEnum.RESPONSABLE_AGRO,
            responsable_equipements: UserRolesEnum.RESPONSABLE_EQUIPEMENTS,
            responsable_icaa: UserRolesEnum.RESPONSABLE_ICAA,
            intervention_admin: UserRolesEnum.INTERVENTION_ADMIN,
        };
        if (Object.keys(aliases).includes(roleName)) {
            return aliases[roleName.toLowerCase()];
        }
        // Defaults to Public
        return UserRolesEnum.PUBLIC;
    }

    /**
     * Returns Role code based on a given alias
     * Example: alias "admin" -> code 12
     */
    getCodeFromAlias(alias: string): UserRolesEnum {
        return this.aliases[alias];
    }

    /**
     * Return string alias for a given Role code
     * Example: role 12 -> alias "admin"
     */
    getAliasFromCode(role: UserRolesEnum): string {
        return this.utils.reverseObject(this.aliases)[role];
    }

    getAliasFromCodeAlt(role: UserRolesEnum): string {
        Object.keys(UserRolesEnum).forEach(k => {
            if (UserRolesEnum[k] === role) {
                return k.toLowerCase();
            }
        });
        return 'public';
    }
}
