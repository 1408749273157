import {ModalController, ToastController} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {DetailCompteurPage} from '../detail-compteur/detail-compteur.page';

import {EquipementDetailPage} from '../equipement-detail/equipement-detail.page';
import {DetailBulletinPage} from '../detail-bulletin/detail-bulletin.page';
import {GuideInstallationPage} from '../components/pages/guide-installation/guide-installation.page';
import {DetailSujetPage} from '../pages/detail-sujet/detail-sujet.page';
import {ISujet} from '../interfaces/ISujet';
import {FicheSiteModalPage} from '../pages/fiche-site-modal/fiche-site-modal.page';
import {DiagFicheSite} from '../interfaces/Diag/DiagFicheSite';
import {SujetMaintenanceModalPage} from '../pages/sujet-maintenance-modal/sujet-maintenance-modal.page';
import {SujetInstallationModalPage} from "../components/pages/sujet-installation-modal/sujet-installation-modal.page";
import {DetailinterventionPage} from "../interventions/detailintervention/detailintervention.page";
import { MinisenseUpPage } from '../minisense-up/minisense-up.page';


@Injectable({
    providedIn: 'root'
})
export class DisplayService {
    today: string;

    constructor(
        private toastController: ToastController,
        private modalController: ModalController
    ) {
    }

    public async presentModalCompteur(programmation) {
        let cpt = 0;
        if (programmation.id_compteur) {
            cpt = programmation.id_compteur;
        } else {
            cpt = programmation.id;
        }
        const modal = await this.modalController.create({
            component: DetailCompteurPage,
            cssClass: 'my-custom-class',
            componentProps: {
                'id_compteur': cpt
            }
        });
        return await modal.present();
    }



    public async showMinisenseUpModal() {
        const modal = await this.modalController.create({
            component: MinisenseUpPage,
            cssClass: 'my-custom-class',
        });
        return await modal.present();
    }

    public async presentModalMinisense(device) {
        const modal = await this.modalController.create({
            component: EquipementDetailPage,
            cssClass: 'my-custom-class',
            componentProps: {
                'id_device': device
            }
        });
        return await modal.present();
    }

    public async presentModalBulletin(bulletin) {

        const modal = await this.modalController.create({
            component: DetailBulletinPage,
            cssClass: 'my-custom-class',
            componentProps: {
                'id_relsp': bulletin.id_relsp
            }
        });
        return await modal.present();
    }

    public async showGuideInstallationModal() {
        const modal = await this.modalController.create({
            component: GuideInstallationPage,
            cssClass: 'my-custom-class',
            componentProps: {},
        });
        return await modal.present();
    }

    public async presentModalSujet(idSujet: string | number) {
        const modal = await this.modalController.create({
            component: DetailSujetPage,
            cssClass: 'my-custom-class',
            componentProps: {
                'id_sujet': idSujet
            }
        });

        modal.onDidDismiss().then(data => {
            if (data.data.update) {
                if (data.data.update == true) {
                    //  this.getData();
                }
            }
        });
        return await modal.present();
    }

    public async presentModalFicheSite(projectId: string | number, ficheSite: DiagFicheSite= null): Promise<void> {
        const modal = await this.modalController.create({
            component: FicheSiteModalPage,
            cssClass: 'my-custom-class',
            componentProps: {
                projectId,
                ficheSite,
            }
        });

        return await modal.present();
    }

    public async presentModalSujetMaintenanceForm(
        sujet: any = null,
        sujetId: string | number = null,
        interventionId: string | number = null,
    ): Promise<void> {
        if (sujet == null && sujetId == null) {
            return;
        }
        const modal = await this.modalController.create({
            component: SujetMaintenanceModalPage,
            cssClass: 'my-custom-class',
            componentProps: {
                sujet,
                sujetId,
                interventionId,
            }
        });

        return await modal.present();
    }

    public async presentModalSujetInstallationForm(
        sujet: any = null,
        sujetId: string | number = null,
        interventionId: string | number = null,
    ): Promise<void> {
        if (sujet == null && sujetId == null) {
            return;
        }
        const modal = await this.modalController.create({
            component: SujetInstallationModalPage,
            cssClass: 'my-custom-class',
            componentProps: {
                sujet,
                sujetId,
                interventionId,
            }
        });

        return await modal.present();
    }

    async presenterModalHistoriqueIntervention(interv_id: string | number) {
        const modal = await this.modalController.create({
            component: DetailinterventionPage,
            cssClass: 'my-custom-class',
            componentProps: {interv_id}
        });

        return await modal.present();
    }

}
