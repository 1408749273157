import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { EVService } from '../services/ev.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

interface SearchResult {
  readingValue: string;
  value: string;
}

interface Message {
  timestamp: string;
  snr: number;
  snr_color: string;
  vbat: number;
  sondes: {
    sonde: string;
    valeur: number;
    unite: string;
  }[];
}

interface MinisenseResponse {
  id_centrale: string;
  nom: string;
  nom_suj: string;
  nom_site?: string;
  nom_orga_prop: string;
  nom_orga_stock: string;
  nom_device: string;
  mode: string;
  datas: {
    messages: Message[];
  };
}

@Component({
  selector: 'app-minisense-up',
  templateUrl: './minisense-up.page.html',
  styleUrls: ['./minisense-up.page.scss'],
})
export class MinisenseUpPage implements OnInit {
  searchText: string = '';
  searchResults: SearchResult[] = [];
  allResults: SearchResult[] = [];
  minisenseData: MinisenseResponse | null = null;
  nbreDeTrames: number = 10; // Valeur par défaut
  isCardOpen: boolean = true; // État initial de la carte

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private http: HttpClient,
    private evService: EVService,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.loadInitialData();
  }

  async loadInitialData() {
    const loading = await this.loadingController.create({
      message: 'Chargement...',
      duration: 0
    });
    await loading.present();

    try {
      const response = await this.evService.listMinisense().toPromise();
      this.allResults = response || [];
      this.searchResults = [...this.allResults];
    } catch (error) {
      console.error('Erreur lors du chargement des données initiales:', error);
    } finally {
      await loading.dismiss();
    }
  }

  handleInput(event: any) {
    const value = event.target.value.toLowerCase();
    if (value) {
      this.searchResults = this.allResults.filter(item =>
        item.readingValue.toLowerCase().includes(value)
      );
    } else {
      this.searchResults = [...this.allResults];
    }
  }

  selectItem(item: SearchResult) {
    this.searchText = item.readingValue;
    this.searchResults = [];
    this.handleSearch();
  }

  async handleSearch() {
    this.minisenseData = null; // Réinitialisation des données précédentes
    const loading = await this.loadingController.create({
      message: 'Recherche en cours...',
      duration: 0
    });
    await loading.present();

    try {
      const response = await this.evService.minisenseUp({
        idMinisense: this.searchText,
        nbre_de_trames: this.nbreDeTrames
      }).toPromise();
      this.minisenseData = response;
    } catch (error) {
      console.error('Erreur lors de la recherche:', error);
    } finally {
      await loading.dismiss();
    }
  }

  toggleCard() {
    this.isCardOpen = !this.isCardOpen;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
