import {Component, Inject, Input, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {isPlatformBrowser} from '@angular/common';
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import {TranslateService} from '@ngx-translate/core';
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
import {EVService} from 'src/app/services/ev.service';
import {ColorSchemeService} from '../../services/colorscheme.service';

@Component({
    selector: 'app-graphe',
    templateUrl: './graphe.component.html',
    styleUrls: ['./graphe.component.scss'],
})


export class GrapheComponent {
    @Input() sujet: any;
    private chart: am4charts.XYChart;
    myDinid = Math.floor(Math.random() * Math.floor(987));
    dateAxis: any;
    period = 12;
    isLoading = false;
    currentTheme: string;
    DataGraph = [];
    haveSmvolt = false;
    haveST = false;
    valueAxis2:any;
    valueAxis1:any;
    objLegendeGraphe:any;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private translate: TranslateService,
        private ev: EVService,
        private colorSchemeService: ColorSchemeService
    ) {
        this.colorSchemeService.load();
        this.currentTheme = this.colorSchemeService.currentActive();
    }

    ngAfterViewInit() {
        this.DataGraph = [];
        this.isLoading = false;
        if (this.sujet.txp_sensors) {
            this.handleData(this.sujet.txp_sensors);
        }
        if (this.sujet.meteo) {
            if (this.sujet.meteo.length > 0) {
                this.handleDataMeteo(this.sujet.meteo);
            }
        }

        if (this.DataGraph.length > 0) {
            //On trie par date
            this.DataGraph.sort((b: { date: Date }, a: { date: Date }) => new Date(b.date).getTime() - new Date(a.date).getTime());
            this.browserOnly(() => {
                am4core.useTheme(am4themes_animated);
                this.chart = am4core.create("chartdiv" + this.myDinid + "", am4charts.XYChart);
                this.chart.data = this.DataGraph;
                this.chart.language.locale = am4lang_fr_FR;
                // Add scrollbar
                this.chart.scrollbarX = new am4core.Scrollbar();
                this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;
                this.chart.scrollbarY = new am4core.Scrollbar();
                this.chart.scrollbarY.parent = this.chart.leftAxesContainer;


                this.valueAxis1 = this.chart.yAxes.push(new am4charts.ValueAxis());
                this.valueAxis1.title.fontSize = 10;
                this.valueAxis1.title.text = 'Valeur à renseigner en bdd';

                this.dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
                this.valueAxis1.min = this.findValueStartingWithS(this.DataGraph, 'min');
                this.valueAxis1.max = this.findValueStartingWithS(this.DataGraph, 'max');
                let range2 = this.dateAxis.axisRanges.create();
                range2.value = new Date();
                range2.grid.stroke = am4core.color("#000000");
                range2.grid.strokeWidth = 2;
                range2.grid.strokeOpacity = 1;
                range2.label.inside = true;
                range2.label.text = this.translate.instant('aujourdhui');
                range2.label.fill = range2.grid.stroke;
                range2.label.rotation = 90;
                range2.label.paddingTop = -22;
                range2.label.paddingLeft = -100;

            //On gère météo commune à tous les dispos
            let seriestair_max = this.chart.series.push(new am4charts.LineSeries());
            seriestair_max.dataFields.valueY = "tair_max";
            seriestair_max.dataFields.dateX = "date";
            seriestair_max.name = this.translate.instant('temp_air_maxi');
            seriestair_max.stroke = am4core.color("#808080");
            seriestair_max.strokeWidth = 2;
            seriestair_max.tensionX = 1;
            seriestair_max.tooltipText = "{name}\n[bold]{valueY}[/]";

            seriestair_max.legendSettings.valueText = "{valueY.close}";
            seriestair_max.legendSettings.itemValueText = "[bold]{valueY}[/bold]";




            if (this.haveSmvolt) {
                let series = this.chart.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = "S_MVOLT";
                series.dataFields.dateX = "date";
                series.name = 'vbat';
                series.stroke = am4core.color('#FF0000');
                series.fill = am4core.color('#FF0000');
                series.strokeWidth = 2;
                series.tensionX = 1;
                series.tooltipText = "{name}\n[bold]{valueY}[/]";
                series.yAxis = this.valueAxis1;
                series.legendSettings.valueText = "{valueY.close}";
                series.legendSettings.itemValueText = "[bold]{valueY}[/bold]";
                series.strokeDasharray = "3,3";
            }

                if(this.sujet.legende_graphe){
                    this.objLegendeGraphe = JSON.parse(this.sujet.legende_graphe);
                    if(this.objLegendeGraphe.ordonnee){
                        this.valueAxis1.title.text = this.objLegendeGraphe.ordonnee;
                    }
                    if (this.objLegendeGraphe.ordonnee.includes("%")) {
                        console.log('max 100 %');
                        this.valueAxis1.max = 100;
                    }
                    if(this.objLegendeGraphe.is_dispo_temp && this.objLegendeGraphe.is_dispo_temp == true){
                        seriestair_max.yAxis = this.valueAxis1;
                        this.valueAxis1.max = 50;
                        this.valueAxis1.min = -10;
                        this.valueAxis1.strictMinMax = true;
                    }else {
                        //Si pas dispo temp on indexe sur plus grande valeur sauf meteo

                        this.valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis());
                        this.valueAxis2.renderer.opposite = true;
                        this.valueAxis2.min = 0;
                        //Ici on doit obtenir la plus haute valeur de la météo ou de la temp sol.
                        if (!this.objLegendeGraphe.ordonnee.includes("%")) {
                            this.valueAxis1.max = this.findValueStartingWithS(this.DataGraph, 'max',false);
                        }
                        this.valueAxis2.max = Math.round(this.obtenirValeurMeteoOuTempsol(this.DataGraph, 'max'));
                        this.valueAxis2.renderer.grid.template.disabled = false;
                        this.valueAxis2.title.text = this.translate.instant('meteo');
                        if (this.valueAxis2) {  seriestair_max.yAxis = this.valueAxis2;}

                        this.valueAxis2.cursorTooltipEnabled = false;
                        if (this.sujet.type_offre == 1 && this.sujet.dispo_code == 1) {
                            this.valueAxis1.min = 0;
                            this.valueAxis1.max = 300;
                            this.valueAxis1.title.text = 'Tension en centibars (cb)';
                        }
                    }
                    console.log('objLegendeGraphe',this.objLegendeGraphe)

                }

                let seriespp_mmjour = this.chart.series.push(new am4charts.ColumnSeries());
                seriespp_mmjour.dataFields.dateX = "date";
                seriespp_mmjour.dataFields.valueY = "pp_mmjour";
                seriespp_mmjour.tooltipText = "{name}\n[bold]{valueY}[/]";
                seriespp_mmjour.name = this.translate.instant('precipitations_mm');
                seriespp_mmjour.stroke = am4core.color("#BFBFBF");
                seriespp_mmjour.fill = am4core.color("#BFBFBF");
                seriespp_mmjour.legendSettings.valueText = "{valueY.close}";
                seriespp_mmjour.legendSettings.itemValueText = "[bold]{valueY}[/bold]";
                seriespp_mmjour.columns.template.strokeOpacity = 1;

                if(this.sujet.legende_graphe){

                    this.objLegendeGraphe = JSON.parse(this.sujet.legende_graphe);
                    if(this.objLegendeGraphe.is_dispo_temp && this.objLegendeGraphe.is_dispo_temp == true){
                        seriespp_mmjour.yAxis = this.valueAxis1;
                    } else {
                        seriespp_mmjour.yAxis = this.valueAxis2;
                    }

                }

                seriespp_mmjour.columns.template.column.cornerRadiusTopRight = 10;
                seriespp_mmjour.columns.template.column.cornerRadiusTopLeft = 10;

                if (this.haveST) {
                    let series_T = this.chart.series.push(new am4charts.LineSeries());
                    series_T.dataFields.valueY = "S_T";
                    series_T.dataFields.dateX = "date";
                    series_T.name = this.translate.instant('sol_température');
                    series_T.stroke = am4core.color('#81BEF7');
                    series_T.fill = am4core.color('#81BEF7');
                    series_T.strokeWidth = 2;
                    series_T.tensionX = 1;
                    series_T.tooltipText = "{name}\n[bold]{valueY}[/]";
                    if(this.sujet.legende_graphe){
                        this.objLegendeGraphe = JSON.parse(this.sujet.legende_graphe);
                        if(this.objLegendeGraphe.is_dispo_temp && this.objLegendeGraphe.is_dispo_temp == true){
                            series_T.yAxis = this.valueAxis1;
                        } else {
                            series_T.yAxis = this.valueAxis2;
                        }
                    }
                    series_T.legendSettings.valueText = "{valueY.close}";
                    series_T.legendSettings.itemValueText = "[bold]{valueY}[/bold]";
                }

            //On itere sur la légénde fournie par l'api pour afficher les sondes
            if(this.sujet.legende) {
                this.sujet.legende.forEach((legende) => {
                    console.log('legende', legende.num_sonde.toUpperCase());
                    let series = this.chart.series.push(new am4charts.LineSeries());
                    series.dataFields.valueY = "S_" + legende.num_sonde.toUpperCase();
                    series.dataFields.dateX = "date";
                    series.name = legende.nom_sonde;
                    series.stroke = am4core.color(legende.coul_code);
                    series.fill = am4core.color(legende.coul_code);
                    series.strokeWidth = 2;
                    series.tensionX = 1;
                    series.tooltipText = "{name}\n[bold]{valueY}[/]";
                    series.yAxis = this.valueAxis1;
                    series.legendSettings.valueText = "{valueY.close}";
                    series.legendSettings.itemValueText = "[bold]{valueY}[/bold]";
                });
            }

            this.chart.exporting.menu = new am4core.ExportMenu();
            this.chart.exporting.adapter.add("data", function (data) {
            for (var i = 0; i < data.data.length; i++) {
                data.data[i].date = new Date(data.data[i].date);
            }
            return data;
            });
            this.chart.cursor = new am4charts.XYCursor();
            var axisTooltip = this.dateAxis.tooltip;
            axisTooltip.background.strokeWidth = 0;
            axisTooltip.background.cornerRadius = 3;
            axisTooltip.background.pointerLength = 0;
            axisTooltip.dy = 5;
            this.dateAxis.tooltipDateFormat = "dd-MM-YYYY HH:mm:ss";
            //this.dateAxis.tooltipText = "{dateX.formatDate('yyyy-mm')}: {valueY.formatNumber('#.00')}";
            var dropShadow = new am4core.DropShadowFilter();
            dropShadow.dy = 1;
            dropShadow.dx = 1;
            dropShadow.opacity = 0.5;
            axisTooltip.filters.push(dropShadow);
                            this.dateAxis.adapter.add("getTooltipText", function (text, target) {
                    return "[bold]" + text + "[/]";
                });
            this.chart.exporting.menu = new am4core.ExportMenu();
            this.chart.exporting.adapter.add("data", function (data) {
                for (var i = 0; i < data.data.length; i++) {
                    data.data[i].date = new Date(data.data[i].date);
                }
                return data;
            });
                this.chart.exporting.filePrefix = this.sujet.nom_suj;
                this.chart.legend = new am4charts.Legend();
                this.chart.legend.togglable = true;
                this.chart.legend.fontSize = 16;
                if (this.currentTheme == 'dark') {
                    this.chart.legend.labels.template.fill = am4core.color("#FFFFFF");
                    this.chart.legend.valueLabels.template.fill = am4core.color("#FFFFFF");
                }

                this.chart.legend.maxHeight = 100;
                this.chart.legend.labels.template.truncate = true;
                this.chart.legend.position = "bottom";
                this.chart.legend.labels.template.maxWidth = 5;
                this.chart.legend.labels.template.maxHeight = 5;
                this.chart.legend.labels.template.truncate = true;
                this.valueAxis1.cursorTooltipEnabled = false;
                let button = this.chart.chartContainer.createChild(am4core.Button);
                button.padding(5, 5, 5, 5);
                button.align = "left";        // Aligner à gauche
                button.valign = "bottom";     // Aligner en bas
                button.marginBottom = 10;     // Ajoutez une marge en bas si nécessaire
                button.marginLeft = 10;
                button.fill = am4core.color("#128713");
                var label = button.createChild(am4core.Label);
                label.text = "+3M";
                label.align = "center";
                label.valign = "middle";
                label.fontSize = 15;
                button.events.on("hit", res => {
                    this.isLoading = true;
                    this.period = this.period + 12;
                    let ccPeriod = 'P' + this.period + 'W';
                        this.ev.loadGraphData(ccPeriod, this.sujet.id_suj).subscribe((data: any) => {
                            this.handleData(data.txp_sensors);
                            this.handleDataMeteo(data.meteo);
                            if (this.valueAxis2) {
                                this.valueAxis2.max = Math.round(this.obtenirValeurMeteoOuTempsol(this.DataGraph, 'max'));
                            }

                            this.chart.data = this.DataGraph;
                            this.isLoading = false;
                        });
                });

            });
        }
    }

    obtenirValeurMeteoOuTempsol(data: any[], searchType: any) {
        let resultValue: number = searchType === 'min' ? Infinity : -Infinity; // Initialise selon le type de recherche

        // Itère sur chaque élément du tableau
        data.forEach((item) => {
          // Itère sur chaque clé de l'objet
          Object.keys(item).forEach((key) => {
            const isMinSearch = searchType === 'min' && item[key] < resultValue;
            const isMaxSearch = searchType === 'max' && item[key] > resultValue;
            if ((key.startsWith('tair_max') || key.startsWith('S_T')) && (isMinSearch || isMaxSearch)) {
              // Si la clé commence par 'S_' et que la condition de recherche est remplie
              resultValue = item[key]; // Met à jour resultValue
            }
          });
        });
        console.log('obtenirValeurMeteoOuTempsol', resultValue);
        return resultValue ;


    }



     findValueStartingWithS(data: any[], searchType: any,isMeteo = true): number {
        let resultValue: number = searchType === 'min' ? Infinity : -Infinity; // Initialise selon le type de recherche

        // Itère sur chaque élément du tableau
        data.forEach((item) => {
            // Itère sur chaque clé de l'objet
            Object.keys(item).forEach((key) => {
                // Vérifie si on doit ignorer les clés commençant par 'S_T' ou 's_t'
                if (!isMeteo && (key.startsWith('S_T') || key.startsWith('s_t'))) {
                    return; // Ignore cette clé
                }

                const isMinSearch = searchType === 'min' && item[key] < resultValue;
                const isMaxSearch = searchType === 'max' && item[key] > resultValue;
                if (key.startsWith('S_') && (isMinSearch || isMaxSearch)) {
                    // Si la clé commence par 'S_' et que la condition de recherche est remplie
                    resultValue = item[key]; // Met à jour resultValue
                }
            });
        });

        if (searchType === 'max') {
            resultValue =  Math.round(resultValue * 1.05)
        } else {
            resultValue =  Math.round(resultValue * 0.85)
        }
        return resultValue ;
      }


    handleDataMeteo(meteoData) {
        for (let entry of meteoData) {
            // if (entry.type_val == 'tair_max' && (parseFloat(entry.val) > parseFloat(this.meteoValue))) {
            //     this.meteoValue = entry.val;

            // }
            let arraDate = new Date(entry.date_val).getTime();
            let magenicIndex = this.DataGraph.findIndex(vendor => vendor.date === arraDate);
            if (magenicIndex != -1) {
                this.DataGraph[magenicIndex][entry.type_val] = parseInt(entry.val);
            } else {
                this.DataGraph.push({
                    'date': new Date(entry.date_val).getTime(),
                    [entry.type_val]: parseInt(entry.val)
                });
            }
        }
    }


    handleData(txpSensors) {
        this.DataGraph = [];
        for (let entry of txpSensors) {
            let name_valeur = "";
            let valeur_affichee = 0;
            Object.keys(entry).map((key) => {
                if (key != 'date_mes' && key != 'date_originale') {
                    name_valeur = key;
                    valeur_affichee = entry[key];
                    if(name_valeur == 'S_MVOLT') {
                        this.haveSmvolt = true;
                        valeur_affichee = valeur_affichee ;
                    }
                    if(name_valeur == 'S_T') {
                        this.haveST = true;

                    }
                    if (name_valeur == 'S_t') {
                        this.haveST = true;
                        name_valeur = 'S_T';
                    }

                    if (name_valeur == 'S_wl') {
                        name_valeur = 'S_WL';
                    }

                    if (name_valeur == 'S_wl') {
                        name_valeur = 'S_WL';
                    }

                    if (name_valeur == 'S_md') {
                        name_valeur = 'S_MD';
                    }

                    name_valeur = name_valeur.toUpperCase();
                    if (this.DataGraph) {
                        let magenicIndex = this.DataGraph.findIndex(vendor => vendor.date === entry.date_originale);
                        if (magenicIndex != -1) {
                            this.DataGraph[magenicIndex][name_valeur] = valeur_affichee;
                        } else {
                            this.DataGraph.push({'date': entry.date_originale, [name_valeur]: valeur_affichee});
                        }
                    } else {
                        this.DataGraph.push({'date': entry.date_originale, [name_valeur]: valeur_affichee});
                    }
                }
            });
        }
        if (this.objLegendeGraphe && !this.objLegendeGraphe.ordonnee.includes("%") && this.valueAxis1.max ) {
            this.valueAxis1.max = this.findValueStartingWithS(this.DataGraph, 'max',false);
        }
        console.log('datagraph',this.DataGraph);
    }

    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngOnDestroy() {
        // Clean up chart when the component is removed
        this.browserOnly(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }
}
